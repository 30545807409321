<script lang="ts">
  import Navbar from "@components/navbar.svelte";
  import ReviewsIOLogo from "@images/review_logo.png?raw";
  import ArrowRight from "~icons/ant-design/caret-right-outlined";
  import StarFilled from "~icons/ant-design/star-filled";
  import { Typography } from "@components/ux/typography";
  import { Image } from "@components/ux/image";

  export let background: any = undefined;
  export let title = "";
  export let subtitle = "";
</script>

<div class="relative flex flex-col">
  <div class="absolute inset-0 -z-50 -mb-16 opacity-30">
    <div
      class=" absolute inset-0 z-10"
      style="background: linear-gradient(180deg, rgba(18, 18, 18, 0.00) 50%, #121212 100%)" />
    <Image src={background} class=" object-cover object-top" />
  </div>

  <Navbar />

  <div class="relative flex items-center justify-center lg:pb-14 lg:pt-14">
    <header
      class="flex max-w-3xl flex-col items-center justify-center gap-8 px-6">
      <div class="flex flex-col items-center gap-4 text-start lg:text-center">
        <Typography variant="h1">
          {title}
        </Typography>
        <Typography variant="h4" as="h2" color="muted">
          {subtitle}
        </Typography>
      </div>

      <div
        class="grid w-full grid-cols-2 gap-y-4 lg:w-auto lg:grid-cols-3 lg:justify-center lg:justify-items-center">
        <div class="col-span-2 flex items-center gap-4 lg:col-span-1">
          <a
            href="https://www.reviews.io/company-reviews/store/smurfers"
            target="_blank">
            <Image
              class="max-h-6 cursor-pointer object-contain"
              src={ReviewsIOLogo} />
          </a>
          <ArrowRight
            class="h-6 min-h-[1.5rem] w-6 min-w-[1.5rem] text-zinc-50" />
        </div>

        <div class="flex items-center gap-2">
          <Typography
            variant="h4"
            as="h3"
            class="inline-flex items-center gap-1"
            >4.87 <StarFilled class="h-3 w-3 md:h-5 md:w-5" /></Typography>
          <Typography variant="large" as="span" color="muted" weight="medium"
            >Out of 5</Typography>
        </div>
        <div class="flex items-center gap-2">
          <Typography variant="h4" as="h3">1.990+</Typography>
          <Typography variant="large" as="span" color="muted" weight="medium"
            >Reviews</Typography>
        </div>
      </div>
    </header>
  </div>
</div>
